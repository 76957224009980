import { useEffect, useState } from 'react';
import { Button } from '@imprivata-cloud/components';
import { findDirectories, findUsers } from '../../../api/portalServices';
import { type Directory, type FindUsersRequest, SortOrder, type User } from '../../../api/types';
import { useNotifications } from '../../../errorHandler/context/Notifications';
import type { AppError } from '../../../errorHandler/errors';
import { useSession } from '../../authorization/context/Session';
import ImprListView from './ImprListView/ImprListView';
import Sync from './Sync';
import UsersEmptyPage from './UsersEmptyPage';
import { UserFieldNames, transformSearchPattern } from './userUtils';
import MenuIcon from '../../../assets/icons/kabob_menu.svg?react';
import GroupsModal from '../groups/GroupsModal';

const Users = () => {
  const { tenantId } = useSession();
  const [directories, setDirectories] = useState<Directory[]>();
  const [users, setUsers] = useState<User[]>();
  const { emitError } = useNotifications();
  const [isLoading, setIsLoading] = useState(true);
  const [isGroupModalVisible, setGroupModalVisible] = useState(false);

  const handleSearch = async (searchValue: string) => {
    console.log('[Users] searchValue:', searchValue);
    const pattern = transformSearchPattern(searchValue);
    const userSearchRequest: FindUsersRequest = {
      selectors: {
        search: {
          pattern,
          fields: [UserFieldNames.DISPLAY_NAME, UserFieldNames.UPN],
        },
        sortFields: [{ field: UserFieldNames.DISPLAY_NAME, order: SortOrder.ASC }],
      },
    };
    return await findUsers(tenantId ?? '', userSearchRequest).then((response) => {
      return response?.users || [];
    });
  };

  useEffect(() => {
    const loadDirectories = async () => {
      return await findDirectories(tenantId ?? '');
    };
    const loadUsers = async () => {
      const request: FindUsersRequest = {
        selectors: {
          sortFields: [{ field: UserFieldNames.DISPLAY_NAME }],
        },
      };
      return await findUsers(tenantId ?? '', request);
    };
    try {
      loadDirectories().then((response) => {
        const directories = response?.directories;
        console.log('response:', response);
        setDirectories(directories);
        if (directories && directories[0]?.syncedGroupCount > 0) {
          loadUsers().then((userRes) => {
            setUsers(userRes?.users);
            setIsLoading(false);
          });
        } else {
          setIsLoading(false);
        }
      });
    } catch (e) {
      console.error('Error fetching directories/users:', e);
      emitError(e as AppError);
      setIsLoading(false);
    }
  }, [tenantId, emitError]);

  if (isLoading) {
    return <></>;
  }

  const MenuButton = () => (
    <Button
      icon={<MenuIcon />}
      size="small"
      onClick={() => setGroupModalVisible(true)}
      style={{ borderColor: 'transparent', boxShadow: 'none' }}
    />
  );

  return (
    <div>
      <GroupsModal isVisible={isGroupModalVisible} onClose={() => setGroupModalVisible(false)} />
      <ImprListView
        enableMenu={false}
        items={users || []}
        customEmptyListNode={<UsersEmptyPage directories={directories} />}
        sortLabel={'All users (by name)'}
        handleSearch={handleSearch}
        rowKey={'upn'}
        headerExtraComponents={[
          <Sync key="sync1" tenantId={tenantId} directoryId={directories?.[0]?.directoryId} />,
          <MenuButton key="menuButton" />,
        ]}
      />
    </div>
  );
};

export default Users;
