// Copyright 2025, Imprivata, Inc.  All rights reserved.

import { SPANS_PORTAL, tracer } from '../utils/tracer';
import { Endpoints } from './constants';
import type {
  DirectorySyncProgressGetRequest,
  DirectorySyncProgressGetResponse,
  DomainsGetResponse,
  DomainsSetRequest,
  EntraIdConsentUrlGetRequest,
  EntraIdConsentUrlGetResponse,
  EntraIdTenantSetupResponse,
  FindDirectoriesResponse,
  FindListRequest,
  FindUsersRequest,
  FindUsersResponse,
  GetClientUserSessionResponse,
  GroupForSyncMarkRequest,
  GroupForSyncUnmarkRequest,
  GroupRequest,
  GroupsFindResponse,
  IdpConfigGetRequest,
  IdpConfigGetResponse,
  IdpConfigUpdateRequest,
  IdpConfigUpdateResponse,
  IdpMetadataGetResponse,
  IdpMetadataUpdateRequest,
  OrgPreferencesGetResponse,
  TenantLookupResponse,
} from './types';
import { fetcher } from './utils';

const lookupTenant = async (userEmail: string, workflowId?: string): Promise<TenantLookupResponse> => {
  return fetcher(Endpoints.TENANT_LOOKUP_BY_DOMAIN, {
    method: 'POST',
    body: JSON.stringify({ userEmail }),
    workflowId,
  });
};

const getClientUserSession = async (workflowId: string): Promise<GetClientUserSessionResponse> => {
  return fetcher(Endpoints.GET_CLIENT_USER_SESSION, {
    method: 'POST',
    workflowId,
  });
};

const getLandingPage = async (workflowId: string, tenantId: string) => {
  return fetcher(
    Endpoints.GET_LANDING_PAGE,
    {
      method: 'POST',
      workflowId,
    },
    tenantId,
  );
};

const getIpRanges = async (tenantId: string): Promise<string[]> => {
  try {
    const response = await fetcher(
      Endpoints.IP_RANGES,
      {
        method: 'POST',
      },
      tenantId,
    );

    return response.cidrs || [];
  } catch (error) {
    console.error('Error fetching IP ranges:', error);
    return [];
  }
};

const getGroups = async (tenantId: string): Promise<GroupRequest[]> => {
  try {
    const response = await fetcher(
      Endpoints.GET_GROUPS,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          ListSelectors: {
            filters: [
              {
                field: 'syncEnabled',
                value: true,
                operator: 'equals',
              },
            ],
          },
        }),
      },
      tenantId,
    );
    console.log('getGroups response: ', response);
    return response.groups || [];
  } catch (error) {
    console.error('Error fetching groups:', error);
    return [];
  }
};

const findDirectories = async (tenantId: string): Promise<FindDirectoriesResponse> => {
  return fetcher(
    Endpoints.DIR_FIND,
    {
      method: 'POST',
    },
    tenantId,
  );
};

const findUsers = async (tenantId: string, request: FindUsersRequest): Promise<FindUsersResponse> => {
  return fetcher(
    Endpoints.USERS_FIND,
    {
      method: 'POST',
      body: JSON.stringify(request),
    },
    tenantId,
  );
};

async function getIdpConfigSAML(data: IdpConfigGetRequest, tenantId: string): Promise<IdpConfigGetResponse> {
  return fetcher(
    Endpoints.GET_IDP_CONFIG_SAML,
    {
      method: 'POST',
      body: JSON.stringify(data),
    },
    tenantId,
  );
}

async function updateIdpConfigSAML(data: IdpConfigUpdateRequest, tenantId: string): Promise<IdpConfigUpdateResponse> {
  return fetcher(
    Endpoints.UPDATE_IDP_CONFIG_SAML,
    {
      method: 'POST',
      body: JSON.stringify(data),
    },
    tenantId,
  );
}

const getEntraIdConsentUrl = async (
  request: EntraIdConsentUrlGetRequest,
  tenantId: string,
): Promise<EntraIdConsentUrlGetResponse> => {
  return fetcher(
    Endpoints.ENTRA_ID_CONSENT,
    {
      method: 'POST',
      body: JSON.stringify(request),
    },
    tenantId,
  );
};

const startEntraIdInitialSync = async (tenantId: string) => {
  return fetcher(
    Endpoints.DIR_INITIAL_SYNC,
    {
      method: 'POST',
      body: JSON.stringify({}), //TODO - check with Erik why this is needed.
    },
    tenantId,
  );
};

export async function directoriesSync(tenantId: string) {
  return fetcher(
    Endpoints.DIR_SYNC,
    {
      method: 'POST',
      body: JSON.stringify({}),
    },
    tenantId,
  );
}

export async function dirSyncProgress(
  tenantId: string,
  directoryId: string,
): Promise<DirectorySyncProgressGetResponse> {
  return fetcher(
    Endpoints.DIR_SYNC_PROGRESS,
    {
      method: 'POST',
      body: JSON.stringify({ directoryId } as DirectorySyncProgressGetRequest),
    },
    tenantId,
  );
}

const completeEntraIdSetup = async (tenantId: string): Promise<EntraIdTenantSetupResponse> => {
  return fetcher(
    Endpoints.ENTRA_ID_SETUP_COMPLETE,
    {
      method: 'POST',
    },
    tenantId,
  );
};

const userLogout = async (tenantId: string) => {
  return fetcher(
    Endpoints.LOGOUT,
    {
      method: 'GET',
    },
    tenantId,
  );
};

export async function orgPreferencesGet(): Promise<OrgPreferencesGetResponse> {
  try {
    tracer.startSpan(SPANS_PORTAL.org_preferences_get);
    const response = await fetcher(Endpoints.PORTAL_ORG_PREFERENCES_GET, { method: 'POST' });
    tracer.endSpan(SPANS_PORTAL.org_preferences_get);
    return response;
  } catch (e) {
    tracer.endSpan(SPANS_PORTAL.org_preferences_get, { error: e });
    throw e;
  }
}

export async function domainsGet(): Promise<DomainsGetResponse> {
  try {
    tracer.startSpan(SPANS_PORTAL.domains_get);
    const response = await fetcher(Endpoints.DOMAINS_GET, { method: 'POST' });
    tracer.endSpan(SPANS_PORTAL.domains_get);
    return response;
  } catch (e) {
    tracer.endSpan(SPANS_PORTAL.domains_get, { error: e });
    throw e;
  }
}

export async function domainsUpdate(name: string): Promise<void> {
  try {
    tracer.startSpan(SPANS_PORTAL.domains_update);
    await fetcher(Endpoints.DOMAINS_UPDATE, {
      method: 'POST',
      body: JSON.stringify({ names: [name] } as DomainsSetRequest),
    });
    tracer.endSpan(SPANS_PORTAL.domains_update);
  } catch (e) {
    tracer.endSpan(SPANS_PORTAL.domains_update, { error: e });
    throw e;
  }
}

export async function idpGet(): Promise<IdpMetadataGetResponse> {
  try {
    tracer.startSpan(SPANS_PORTAL.idp_get);
    const response = await fetcher(Endpoints.IDP_GET, { method: 'POST' });
    tracer.endSpan(SPANS_PORTAL.idp_get);
    return response;
  } catch (e) {
    tracer.endSpan(SPANS_PORTAL.idp_get, { error: e });
    throw e;
  }
}

export async function idpUpdate(data: IdpMetadataUpdateRequest): Promise<void> {
  try {
    tracer.startSpan(SPANS_PORTAL.idp_update);
    const response = await fetcher(Endpoints.IDP_UPDATE, {
      method: 'POST',
      body: JSON.stringify(data),
    });
    tracer.endSpan(SPANS_PORTAL.idp_update);
    return response;
  } catch (e) {
    tracer.endSpan(SPANS_PORTAL.idp_update, { error: e });
    throw e;
  }
}

const markGroupForSync = async (data: GroupForSyncMarkRequest, tenantId: string): Promise<void> => {
  return fetcher(
    Endpoints.MARK_GROUP_SYNC,
    {
      method: 'POST',
      body: JSON.stringify(data),
    },
    tenantId,
  );
};

const unmarkGroupForSync = async (data: GroupForSyncUnmarkRequest, tenantId: string): Promise<void> => {
  return fetcher(
    Endpoints.UNMARK_GROUP_SYNC,
    {
      method: 'POST',
      body: JSON.stringify(data),
    },
    tenantId,
  );
};

const findGroups = async (data: FindListRequest, tenantId: string): Promise<GroupsFindResponse> => {
  return fetcher(
    Endpoints.GROUPS_FIND,
    {
      method: 'POST',
      body: JSON.stringify(data),
    },
    tenantId,
  );
};

export {
  lookupTenant,
  getClientUserSession,
  getEntraIdConsentUrl,
  getLandingPage,
  getIpRanges,
  getGroups,
  userLogout,
  completeEntraIdSetup,
  startEntraIdInitialSync,
  findDirectories,
  getIdpConfigSAML,
  updateIdpConfigSAML,
  findUsers,
  markGroupForSync,
  unmarkGroupForSync,
  findGroups,
};
