// Copyright 2024, Imprivata, Inc.  All rights reserved.

import { Button } from '@imprivata-cloud/components';
import { Form, Upload } from 'antd';
import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import UploadImg from '../../assets/icons/upload.svg?react';
import './LogoUpload.less';
import type { RcFile } from 'antd/es/upload';
import { ErrorCode } from '../../errorHandler/constants';
import { useNotifications } from '../../errorHandler/context/Notifications';
import { AppError } from '../../errorHandler/errors';
import { getBase64, type FileType } from '../../setup/hooks/useOrganizationInfoSave';
import type { UploadChangeParam } from 'antd/es/upload/interface';
import type { RuleObject } from 'antd/es/form';

export type LogoUploadProps = {
  isOptional?: boolean;
  oldImageUrl?: string;
  fieldName: string;
  fieldValue: File | undefined;
};

export function LogoUpload({ isOptional, oldImageUrl, fieldName, fieldValue }: LogoUploadProps) {
  const { t } = useTranslation();
  const [imageUrl, setImageUrl] = useState<string | undefined>();
  const { emitError } = useNotifications();

  const setImageUrlFromFile = useCallback(async (fieldValue: File | undefined) => {
    if (fieldValue) {
      const url = await getBase64(fieldValue as FileType);
      setImageUrl(url);
    } else {
      setImageUrl(undefined);
    }
  }, []);

  useEffect(() => {
    void setImageUrlFromFile(fieldValue);
  }, [setImageUrlFromFile, fieldValue]);

  function beforeUpload(file: FileType) {
    return new Promise<FileType>((resolve, reject) => {
      const isFileTypeOk = file.type.startsWith('image');
      if (!isFileTypeOk) {
        onInvalidImage(ErrorCode.INVALID_FILE_TYPE);
        reject();
        return;
      }

      const isFileSizeOk = file.size <= 100_000;
      if (!isFileSizeOk) {
        onInvalidImage(ErrorCode.LOGO_UPLOAD_ERROR);
        reject();
        return;
      }

      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        const image = new Image();
        image.src = e?.target?.result as string;
        image.onload = () => {
          const height = image.height;
          const width = image.width;
          if (height > 100 || width > 200) {
            onInvalidImage(ErrorCode.LOGO_UPLOAD_ERROR);
            reject();
          } else {
            resolve(file);
          }
        };
      };
      fileReader.readAsDataURL(file);
    });
  }

  async function validator(_: RuleObject, file: RcFile) {
    await setImageUrlFromFile(file);
    if (!file) {
      if (isOptional) {
        return Promise.resolve();
      }
      return Promise.reject(new Error('The image does not satisfy the requirements.'));
    }
    return Promise.resolve();
  }

  function onInvalidImage(errorCode: ErrorCode) {
    emitError(new AppError({ code: errorCode }));
  }

  function getValueFromEvent(e: UploadChangeParam): RcFile | undefined {
    return e?.file?.originFileObj;
  }

  // valuePropName fixes the console error
  return <Form.Item
    name={fieldName}
    valuePropName={'file'}
    getValueFromEvent={getValueFromEvent}
    rules={[{ validator: validator }]}
  >
    <Upload
      accept="image/*"
      customRequest={({ onSuccess }) => onSuccess?.('ok')}
      maxCount={1}
      showUploadList={false}
      beforeUpload={beforeUpload}
      className="logo-upload-container"
    >
      <div>
        <label>{t("common.logo-upload.label")}</label>
        {<div className="logo-upload-preview">
          {(imageUrl || oldImageUrl) && <div><img src={imageUrl || oldImageUrl} alt="organization logo" /></div>}
          <Button type={"text"}><UploadImg /> {t('common.logo-upload.button')}</Button>
        </div>}
      </div>
    </Upload>
  </Form.Item>;
}

export default LogoUpload;
