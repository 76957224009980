// Copyright 2025, Imprivata, Inc.  All rights reserved.

import './ItemList.less';
import type React from 'react';

export interface ListItemProps {
  name: string;
  upn: string;
  'data-testid'?: string;
  className?: string;
  rowMenu?: React.ReactNode;
}

const ItemList = (props: ListItemProps) => {
  return (
    <div className={'list-item-container'} data-testid={'list-item'}>
      <div className="list-item-left">
        <div>
          <div data-testid="list-item-primary-text" className="list-item-primary-text">
            {props.name}
          </div>
          {props.upn ? (
            <div data-testid="list-item-secondary-text" className="list-item-secondary-text">
              {props.upn}
            </div>
          ) : null}
        </div>
      </div>
      <div className="list-item-right">{props.rowMenu ? props.rowMenu : null}</div>
    </div>
  );
};

export default ItemList;
