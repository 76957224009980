import { ALL_ITEMS_PATTERN } from '../../../api/types';

export const processSearchWithSpaces = (pattern: string): string => {
  return pattern
    .trim()
    .split(/ +/)
    .map((word) => `*${word}*`)
    .join(' ');
};

export const transformSearchPattern = (pattern: string): string => {
  const specialSymbols = /[!#$%^&*()?":{}|<>]/;

  if (pattern.length === 0) {
    return ALL_ITEMS_PATTERN;
  }
  if (pattern.includes('*')) {
    return pattern;
  }
  if (pattern.includes(' ')) {
    return processSearchWithSpaces(pattern);
  }
  if (specialSymbols.test(pattern)) {
    return '';
  }
  return `*${pattern}*`;
};

export enum UserFieldNames {
  DISPLAY_NAME = 'display-name',
  UPN = 'upn',
}
