import { SyncOutlined } from '@ant-design/icons';
import { Button } from '@imprivata-cloud/components';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { dirSyncProgress } from '../../../api/portalServices';
import { type DirectorySyncProgressGetResponse, SyncStatus } from '../../../api/types';
import { useNotifications } from '../../../errorHandler/context/Notifications';
import { type ApiError, AppError } from '../../../errorHandler/errors';
import { formatDateInUtcWithTz } from '../../../utils/utils';
import useDirectoriesSync from '../../hooks/useDirectoriesSync';
import './Sync.less';

export type SyncProps = {
  tenantId?: string;
  directoryId?: string;
};

export default function Sync({ tenantId, directoryId }: SyncProps) {
  const { t } = useTranslation();
  if (!tenantId || !directoryId) {
    return null;
  }

  const { emitError, clearErrorNotifications } = useNotifications();
  const [isLoading, setIsLoading] = useState(true);
  const [isSyncRunning, setIsSyncRunning] = useState(false);
  const [syncProgressResponse, setSyncProgressResponse] = useState<DirectorySyncProgressGetResponse | undefined>();
  const { mutate: runSync } = useDirectoriesSync();

  const fetchSyncProgress = useCallback(async () => {
    try {
      clearErrorNotifications();
      setIsLoading(true);
      const resp = await dirSyncProgress(tenantId, directoryId);
      setSyncProgressResponse(resp);
      setIsSyncRunning(resp?.syncStatus === SyncStatus.RUNNING);
      setIsLoading(false);
    } catch (e) {
      emitError(new AppError(e as ApiError));
    }
  }, [clearErrorNotifications, emitError, tenantId, directoryId]);

  useEffect(() => {
    void fetchSyncProgress();
  }, [fetchSyncProgress]);

  if (isLoading) {
    return null;
  }

  return (
    <div className="sync-container">
      <Button
        type="text"
        label={t('portal.settings.users.update-button')}
        icon={<SyncOutlined />}
        disabled={isSyncRunning}
        onClick={() => {
          runSync(tenantId);
          setIsSyncRunning(true);
        }}
      />
      <span className="update-date" style={{ visibility: isSyncRunning ? 'hidden' : 'visible' }}>
        {t('portal.settings.users.update-date')}: {formatDateInUtcWithTz(syncProgressResponse?.latestSyncAt)}
      </span>
    </div>
  );
}
