import { useState } from 'react';
import ExternalIdpGroups from './ExternalIdpGroups';
import ExternalIdpInfo from './ExternalIdpInfo';

const ExternalIdpStep = () => {
  const [idpInfoSaved, setIdpInfoSaved] = useState(false);
  const [metadataId, setMetadataId] = useState<string | null>(null);

  return idpInfoSaved ? (
    <ExternalIdpGroups metadataId={metadataId ?? ''} />
  ) : (
    <ExternalIdpInfo setIdpInfoSaved={setIdpInfoSaved} setMetadataId={setMetadataId} />
  );
};

export default ExternalIdpStep;
