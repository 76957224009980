// Copyright 2025, Imprivata, Inc.  All rights reserved.

import { useMutation } from '@tanstack/react-query';
import { saveIdpConfigSAML } from '../../api/setupServices';
import { STEPS } from '../constants';
import { useNavigateToNextStep } from '../hooks/useNavigateToNextStep';
import { useNotifications } from '../../errorHandler/context/Notifications';
import { AppError } from '../../errorHandler/errors';
import { ErrorCode } from '../../errorHandler/constants';

const useSaveIdpConfigSAML = (metadataId: string) => {
  const navigateToNextStep = useNavigateToNextStep();
  const { emitError } = useNotifications();

  return useMutation({
    mutationFn: async (values: { samlAttributeName: string; samlAttributeValue: string }) => {
      const requestData = {
        idpMetadataId: metadataId,
        samlGroupAttrName: values.samlAttributeName,
        samlGroupAttrValues: values.samlAttributeValue.split(',').map((item) => item.trim()),
      };
      return saveIdpConfigSAML(requestData);
    },
    onSuccess: (data) => {
      console.log('IdP Config saved:', data.idpConfigId);
      navigateToNextStep(STEPS.ORG_INFO);
    },
    onError: (error) => {
      emitError(new AppError({ code: ErrorCode.INCOMPLETE_OPERATION }));
      console.log('Error saving IdP SAML Config:', error);
    },
  });
};

export default useSaveIdpConfigSAML;
