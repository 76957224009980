// Copyright 2025, Imprivata, Inc.  All rights reserved.

import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogVariant, ImprDialog } from '@imprivata-cloud/components';
import GroupsModalContent from './GroupsModalContent';
import { useMarkGroup, useUnmarkGroupForSync } from '../../hooks/useGroups';

interface GroupsModalProps {
  isVisible: boolean;
  onClose: () => void;
}

const TRANSLATION_ROOT = 'portal.settings.groups';

const GroupsModal = ({ isVisible, onClose }: GroupsModalProps) => {
  const { t } = useTranslation();
  const [groupsToMark, setGroupsToMark] = useState<string[]>([]);
  const [groupsIdToMark, setGroupsIdToMark] = useState<string[]>([]);
  const [groupsToUnmark, setGroupsToUnmark] = useState<string[]>([]);
  const markGroup = useMarkGroup();
  const unmarkGroupForSync = useUnmarkGroupForSync();

  const handleOk = () => {
    for (const group of groupsIdToMark) {
      markGroup.mutate(group);
    }
    for (const group of groupsToUnmark) {
      unmarkGroupForSync.mutate(group);
    }

    onClose();
    setGroupsToMark([]);
    setGroupsToUnmark([]);
  };

  return (
    <ImprDialog
      destroyOnClose
      title={t(`${TRANSLATION_ROOT}.title`)}
      type={DialogVariant.HEADER_DUAL_BUTTON}
      cancelButtonProps={{ label: t('common.cancel'), onClick: onClose }}
      submitButtonProps={{
        label: t('common.save'),
        disabled: groupsToMark.length === 0 && groupsToUnmark.length === 0,
        onClick: handleOk,
      }}
      open={isVisible}
      width={800}
      onCancel={onClose}
      content={
        <GroupsModalContent
          key={isVisible ? Date.now() : undefined}
          groupsIdToMark={groupsIdToMark}
          setGroupsIdToMark={setGroupsIdToMark}
          groupsToMark={groupsToMark}
          setGroupsToMark={setGroupsToMark}
          groupsToUnmark={groupsToUnmark}
          setGroupsToUnmark={setGroupsToUnmark}
        />
      }
    />
  );
};

export default GroupsModal;
