// Copyright 2024, Imprivata, Inc.  All rights reserved.

import { Button, InputBox } from '@imprivata-cloud/components';
import { Form } from 'antd';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './Appearance.less';
import { CloseCircleOutlined, SaveOutlined } from '@ant-design/icons';
import { SystemType } from '../../../api/constants';
import { domainsGet, domainsUpdate, orgPreferencesGet } from '../../../api/portalServices';
import type { OrgPreferencesGetResponse } from '../../../api/types';
import LogoUpload from '../../../components/logoUpload/LogoUpload';
import { ErrorCode } from '../../../errorHandler/constants';
import { useNotifications } from '../../../errorHandler/context/Notifications';
import { type ApiError, AppError } from '../../../errorHandler/errors';
import useOrganizationInfoSave from '../../../setup/hooks/useOrganizationInfoSave';
import { withRole } from '../../authorization/Roles';
import { ROLE_TYPE_ADMIN } from '../../constants';

enum FieldNames {
  LOGO = 'logo',
  ORG_NAME = 'orgName',
  DOMAIN = 'domain',
}

interface FormValues {
  [FieldNames.ORG_NAME]: string;
  [FieldNames.DOMAIN]: string;
  [FieldNames.LOGO]: File;
}

const TRANSLATION_ROOT = 'portal.appearance';

function Appearance() {
  const { t } = useTranslation();
  const [form] = Form.useForm<FormValues>();
  const { emitError, clearErrorNotifications } = useNotifications();
  const organizationInfoSave = useOrganizationInfoSave(SystemType.PORTAL);
  const orgPreferencesGetResponse = useRef<OrgPreferencesGetResponse>();
  const [isLoading, setIsLoading] = useState(true);
  const [isFormDirty, setIsFormDirty] = useState(false);

  const fetchData = useCallback(async () => {
    try {
      clearErrorNotifications();
      setIsLoading(true);
      const orgPreferences = await orgPreferencesGet();
      form.setFieldValue(FieldNames.ORG_NAME, orgPreferences.orgName);
      setLogoFieldValue(undefined);
      orgPreferencesGetResponse.current = orgPreferences;
      const domainsResponse = await domainsGet();
      form.setFieldValue(FieldNames.DOMAIN, domainsResponse.names[0]);
      setIsFormDirty(false);
      setIsLoading(false);
      // this will clear errors in the form if any left from pre-fetch user changes
      await form.validateFields();
    } catch (e) {
      emitError(new AppError(e as ApiError));
    }
  }, [form.setFieldValue, emitError, clearErrorNotifications, form.validateFields]);

  useEffect(() => {
    void fetchData();
  }, [fetchData]);

  async function onFinish(formValues: FormValues) {
    if (!orgPreferencesGetResponse.current?.orgName) {
      emitError(AppError.fromMessage('Invalid state exception: orgName cannot be empty.'));
      return;
    }
    if (!isFormDirty) {
      return;
    }
    try {
      setIsLoading(true);
      clearErrorNotifications();
      await organizationInfoSave.mutateAsync({
        orgName: formValues[FieldNames.ORG_NAME],
        usernameLabel: orgPreferencesGetResponse.current.usernameLabel,
        passwordLabel: orgPreferencesGetResponse.current.passwordLabel,
        proxCardLabel: orgPreferencesGetResponse.current.proxCardLabel,
        logoFile: formValues[FieldNames.LOGO],
        oldLogoUrl: orgPreferencesGetResponse.current?.logoUrl,
      });
      await domainsUpdate(formValues[FieldNames.DOMAIN]);
      await fetchData();
    } catch (e) {
      const error = e as AppError;
      if (error?.code === ErrorCode.ALREADY_EXISTS) {
        error.code = ErrorCode.DOMAIN_ALREADY_EXISTS;
      }
      emitError(error);
    } finally {
      setIsLoading(false);
    }
  }

  function setLogoFieldValue(file: File | undefined) {
    return form.setFieldValue([FieldNames.LOGO], file);
  }

  return (
    <div className={'appearance-container'}>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onValuesChange={() => setIsFormDirty(true)}
        className="appearance-form"
      >
        <Form.Item name={FieldNames.ORG_NAME} rules={[{ required: true }]}>
          <InputBox label={t(`${TRANSLATION_ROOT}.org-name-label`)} autoFocus />
        </Form.Item>

        <Form.Item name={FieldNames.DOMAIN} rules={[{ required: true }]}>
          <InputBox label={t(`${TRANSLATION_ROOT}.domain-label`)} />
        </Form.Item>

        <LogoUpload
          isOptional={true}
          oldImageUrl={orgPreferencesGetResponse.current?.logoUrl}
          fieldName={FieldNames.LOGO}
          fieldValue={form.getFieldValue(FieldNames.LOGO)}
        />

        <div>
          <Button onClick={fetchData}>
            <CloseCircleOutlined />
            {t('common.cancel')}
          </Button>
          <Button type="primary" onClick={form.submit} htmlType="submit" disabled={isLoading || !isFormDirty}>
            <SaveOutlined />
            {t('common.save')}
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default withRole({ authorizedRoles: [ROLE_TYPE_ADMIN] })(Appearance);
