import { useMutation } from '@tanstack/react-query';
import { completeEntraIdSetup } from '../../api/portalServices';
import { useNotifications } from '../../errorHandler/context/Notifications';
import { type ApiError, AppError } from '../../errorHandler/errors';
import { useSession } from '../authorization/context/Session';
import useEntraIdInitialSync from './useEntraIdInitialSync';

const useEntraIdSetupComplete = () => {
  const { emitError } = useNotifications();
  const { mutate: startInitialSync } = useEntraIdInitialSync();
  const { tenantId } = useSession();

  return useMutation({
    mutationFn: async (tenantId: string) => {
      await completeEntraIdSetup(tenantId);
    },
    onSuccess: async () => {
      console.log('Entra ID setup completed');
      startInitialSync(tenantId || '');
    },
    onError: (e: Error) => {
      console.log('Entra ID setup failed', e);
      const apiError = e as unknown as ApiError;
      emitError(new AppError(apiError));
    },
  });
};

export default useEntraIdSetupComplete;
