import { LinkDeadErrorView } from '../setup/containers/errors/LinkDeadErrorView';
import { ErrorView } from './ErrorView';
import { type ErrorConfigMap, ErrorType } from './types';

export const UNEXPECTED_ERROR_CODE = 'unexpected-error';
export enum GLOBAL_ERRORS {
  FATAL = 'fatal',
  NOT_FOUND = 'not-found',
  PERMISSION_DENIED = 'forbidden',
  IMPLEMENTATION_DEFECT = 'implementation-defect',
  INTERNAL = 'internal',
  INTERNAL_1 = 'INTERNAL',
  INVALID_TENANT_ID = 'invalid-tenant-id',
  INVALID_TENANT_STATE = 'invalid-tenant-state',
  INCOMPLETE_OPERATION = 'incomplete-operation',
  SESSION_EXPIRED = 'session-expired',
  INVALID_FILE_TYPE = 'invalid-file-type',
  LOGO_UPLOAD_ERROR = 'logo-upload-error',
  INVALID_ENTRA_TENANT_ID = 'invalid-aad-tenant-id',
  ENTRA_ID_AUTH_ERROR = 'entra-id-auth-error',
  DOMAIN_ALREADY_EXISTS = 'domain-already-exists',
  DIRECTORIES_SYNC_ERROR = 'directory-sync-error',
}

export enum FULL_PAGE_ERRORS {
  SYSTEM_ERROR = 'SYSTEM_ERROR',
  FATAL_ERROR = 'FATAL_ERROR',
  LINK_DEAD = 'link-dead',
}

export const errorConfiguration: ErrorConfigMap = {
  [GLOBAL_ERRORS.NOT_FOUND]: {
    errorCode: GLOBAL_ERRORS.NOT_FOUND,
    type: 'warning',
    errorType: ErrorType.GLOBAL,
  },
  [GLOBAL_ERRORS.PERMISSION_DENIED]: {
    errorCode: GLOBAL_ERRORS.PERMISSION_DENIED,
    type: 'warning',
    errorType: ErrorType.GLOBAL,
  },
  [GLOBAL_ERRORS.IMPLEMENTATION_DEFECT]: {
    errorCode: UNEXPECTED_ERROR_CODE,
    type: 'warning',
    errorType: ErrorType.GLOBAL,
  },
  [GLOBAL_ERRORS.INTERNAL]: {
    errorCode: UNEXPECTED_ERROR_CODE,
    type: 'warning',
    errorType: ErrorType.GLOBAL,
  },
  [GLOBAL_ERRORS.INTERNAL_1]: {
    errorCode: UNEXPECTED_ERROR_CODE,
    type: 'warning',
    errorType: ErrorType.GLOBAL,
  },
  [GLOBAL_ERRORS.INVALID_TENANT_ID]: {
    errorCode: UNEXPECTED_ERROR_CODE,
    type: 'warning',
    errorType: ErrorType.GLOBAL,
  },
  [GLOBAL_ERRORS.INVALID_TENANT_STATE]: {
    errorCode: UNEXPECTED_ERROR_CODE,
    type: 'warning',
    errorType: ErrorType.GLOBAL,
  },
  [GLOBAL_ERRORS.INCOMPLETE_OPERATION]: {
    errorCode: UNEXPECTED_ERROR_CODE,
    type: 'warning',
    errorType: ErrorType.GLOBAL,
  },
  [GLOBAL_ERRORS.SESSION_EXPIRED]: {
    errorCode: GLOBAL_ERRORS.SESSION_EXPIRED,
    type: 'warning',
    errorType: ErrorType.GLOBAL,
  },
  [GLOBAL_ERRORS.FATAL]: {
    errorCode: UNEXPECTED_ERROR_CODE,
    type: 'warning',
    errorType: ErrorType.GLOBAL,
  },
  [GLOBAL_ERRORS.INVALID_FILE_TYPE]: {
    errorCode: GLOBAL_ERRORS.INVALID_FILE_TYPE,
    type: 'error',
    errorType: ErrorType.GLOBAL,
  },
  [GLOBAL_ERRORS.LOGO_UPLOAD_ERROR]: {
    errorCode: GLOBAL_ERRORS.LOGO_UPLOAD_ERROR,
    type: 'error',
    errorType: ErrorType.GLOBAL,
  },
  [GLOBAL_ERRORS.INVALID_ENTRA_TENANT_ID]: {
    errorCode: GLOBAL_ERRORS.INVALID_ENTRA_TENANT_ID,
    type: 'warning',
    errorType: ErrorType.GLOBAL,
  },
  [GLOBAL_ERRORS.ENTRA_ID_AUTH_ERROR]: {
    errorCode: GLOBAL_ERRORS.ENTRA_ID_AUTH_ERROR,
    type: 'error',
    errorType: ErrorType.GLOBAL,
  },
  [GLOBAL_ERRORS.DIRECTORIES_SYNC_ERROR]: {
    errorCode: GLOBAL_ERRORS.DIRECTORIES_SYNC_ERROR,
    type: 'warning',
    errorType: ErrorType.GLOBAL,
  },
  [FULL_PAGE_ERRORS.SYSTEM_ERROR]: {
    errorCode: UNEXPECTED_ERROR_CODE,
    type: 'warning',
    errorType: ErrorType.FULL_PAGE,
    errorView: <ErrorView />,
  },
  [FULL_PAGE_ERRORS.FATAL_ERROR]: {
    errorCode: UNEXPECTED_ERROR_CODE,
    type: 'warning',
    errorType: ErrorType.FULL_PAGE,
    errorView: <ErrorView />,
  },
  [FULL_PAGE_ERRORS.LINK_DEAD]: {
    errorCode: UNEXPECTED_ERROR_CODE,
    type: 'warning',
    errorType: ErrorType.FULL_PAGE,
    errorView: <LinkDeadErrorView />,
  },
  [GLOBAL_ERRORS.DOMAIN_ALREADY_EXISTS]: {
    errorCode: GLOBAL_ERRORS.DOMAIN_ALREADY_EXISTS,
    type: 'error',
    errorType: ErrorType.GLOBAL,
  },
};
