// Copyright 2025, Imprivata, Inc.  All rights reserved.

import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import * as api from '../../api/portalServices';
import { useSession } from '../../portal/authorization/context/Session';
import { transformSearchPattern } from '../containers/users/userUtils';

export const useGroups = (searchValue: string) => {
  const { tenantId = '' } = useSession();

  // Get added groups
  const { data: addedGroupsData } = useQuery({
    queryKey: ['added-groups'],
    queryFn: () =>
      api.findGroups(
        { selectors: { filters: [{ field: 'sync-enabled', value: 'true', operator: 'equals' }] } },
        tenantId,
      ),
    retry: false,
  });
  const addedGroups = addedGroupsData?.groups || [];

  // Get available groups
  const { data: availableGroupsData, refetch } = useQuery({
    queryKey: ['available-groups', searchValue],
    queryFn: () =>
      api.findGroups(
        {
          selectors: {
            search: {
              pattern: transformSearchPattern(searchValue),
              fields: ['name'],
            },
            filters: [{ field: 'sync-enabled', value: 'false', operator: 'equals' }],
          },
        },
        tenantId,
      ),
    enabled: searchValue.length >= 2,
    retry: false,
  });
  const availableGroups = availableGroupsData?.groups || [];

  return {
    addedGroups,
    availableGroups,
    refetchAvailableGroups: refetch,
  };
};

export const useMarkGroup = () => {
  const queryClient = useQueryClient();
  const { tenantId = '' } = useSession();

  return useMutation({
    mutationFn: (groupId: string) => api.markGroupForSync({ groupId: groupId, isAdmin: false }, tenantId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['added-groups'] });
    },
  });
};

export const useUnmarkGroupForSync = () => {
  const queryClient = useQueryClient();
  const { tenantId = '' } = useSession();

  return useMutation({
    mutationFn: (groupId: string) => api.unmarkGroupForSync({ groupId: groupId }, tenantId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['added-groups'] });
    },
  });
};
