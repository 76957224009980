// Copyright 2024, Imprivata, Inc.  All rights reserved.
// NOTE: This is temporary until feature flags have been properly implemented
import { _getEnv } from './api/utils';
import featureFlags from './featureFlags.json';
export type FeatureFlags = typeof featureFlags;

export type FeatureConfig = {
  PENDO_API_KEY: string;
  USERS_PAGE: boolean;
};

export function getFeatureFlags(): FeatureConfig {
  const currentEnv = _getEnv();
  let features: FeatureConfig = featureFlags['prod' as keyof FeatureFlags];

  if (Object.keys(featureFlags).includes(currentEnv)) {
    features = featureFlags[currentEnv as keyof FeatureFlags];
  }

  console.debug('[featureFlagUtils] feature flags: ', {
    currentEnv,
    features,
  });
  return features;
}
