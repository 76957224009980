import { type PropsWithChildren, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { getClientUserSession } from '../../../api/portalServices';
import type { UserInfo } from '../../../api/types';
import { initTracerPortal } from '../../../utils/tracer';
import { PortalLogin } from '../../containers/login/PortalLogin';
import { initializePendo } from '../../../utils/pendo';

export type SessionState = {
  tenantId?: string;
  workflowId?: string;
  userInfo?: UserInfo;
};

export const SessionContext = createContext<(SessionState & { clearSession: () => void }) | null>(null);

export const useSession = () => {
  const context = useContext(SessionContext);
  if (!context) {
    throw new Error('useSession must be used within a SessionContext');
  }
  return context;
};

export const Session = ({ children }: PropsWithChildren) => {
  const [session, setSession] = useState<SessionState>();
  const [loading, setLoading] = useState(true);
  const [workflowId, setWorkflowId] = useState<string>();

  useEffect(() => {
    if (!session) {
      const wid = uuidv4();
      initTracerPortal(wid); //TODO revisit this - causing issues within react query
      const checkUserSession = async () => {
        // call getClientUserSession to check if the user is logged in
        return await getClientUserSession('');
      };
      setWorkflowId(wid);

      checkUserSession()
        .then((response) => {
          if (response) {
            initializePendo(response.tenantId, response.userInfo);
            setSession({ tenantId: response.tenantId, userInfo: response.userInfo, workflowId: wid });
            setWorkflowId(wid);
            setLoading(false);
          }
        })
        .catch((e) => {
          console.log('Error fetching user session:', e);
          setLoading(false);
        });
    }
  }, [session]);

  const clearSession = useCallback(() => {
    setSession(undefined);
  }, []);

  const contextValue = useMemo(
    () => ({
      ...session,
      clearSession,
    }),
    [session, clearSession],
  );

  if (loading) {
    return <div> </div>;
  }
  if (!session || !session.tenantId) {
    return <PortalLogin workflowId={workflowId} />;
  }

  return <SessionContext.Provider value={contextValue}>{children}</SessionContext.Provider>;
};
