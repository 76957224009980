import { InputBox } from '@imprivata-cloud/components';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import ContinueButton from '../../../components/continueButton/ContinueButton';
import { STEPS } from '../../constants';
import useSaveIdpConfigSAML from '../../hooks/useSaveIdpConfigSAML';

const ExternalIdpGroups = ({ metadataId }: { metadataId: string }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const TRANSLATION_ROOT = `setup.${STEPS.EXTERNAL_IDP_INFO}`;
  const mutation = useSaveIdpConfigSAML(metadataId);

  return (
    <div>
      <p>
        {t(`${TRANSLATION_ROOT}.groups.label`)}
        <a href={'https://docs.imprivata.com/'} target={'_blank'} rel="noreferrer">
          {' '}
          See instructions.
        </a>
      </p>
      <Form form={form} onFinish={(values) => mutation.mutate(values)}>
        {/*SAML Attribute Name */}
        <Form.Item name={'samlAttributeName'} rules={[{ required: true, message: '' }]}>
          <InputBox
            label={t(`${TRANSLATION_ROOT}.groups.saml-attribute-name`)}
            size="large"
            data-testid="saml-attribute-name"
            className={'input'}
          />
        </Form.Item>
        {/*SAML Attribute Value */}
        <Form.Item name={'samlAttributeValue'} rules={[{ required: true, message: '' }]}>
          <InputBox
            label={t(`${TRANSLATION_ROOT}.groups.saml-attribute-value`)}
            size="large"
            data-testid="saml-attribute-value"
            className={'input'}
          />
        </Form.Item>
        <Form.Item style={{ marginTop: '2rem' }}>
          <ContinueButton htmlType={'submit'} validateDirty={['samlAttributeName', 'samlAttributeValue']}>
            {t('common.continue-button')}
          </ContinueButton>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ExternalIdpGroups;
