import { Button, ImprList, type ImprListProps } from '@imprivata-cloud/components';
import { Popover, type TableColumnsType } from 'antd';
import type React from 'react';
import ItemList from './ItemList';

export interface MenuButtonProps {
  enableMenu: boolean;
  menuIcon?: React.ReactNode;
  menuContent?: React.ReactNode;
  menuTitle?: string;
  menuClick?: () => void;
}

const ImprListView = <T extends Record<string, unknown>>({ rowKey, ...props }: ImprListProps<T> & MenuButtonProps) => {
  const columns: TableColumnsType<Record<string, unknown>> = [
    {
      title: 'Display Name',
      dataIndex: 'displayName',
      key: 'displayName',
      render: (_, data) => (
        <ItemList
          name={data.displayName as string}
          upn={data.upn as string}
          rowMenu={
            props.enableMenu ? (
              <RowMenuButton
                enableMenu={props.enableMenu}
                menuIcon={props.menuIcon}
                menuTitle={props.menuTitle}
                menuContent={props.menuContent}
                menuClick={props.menuClick}
              />
            ) : (
              <></>
            )
          }
        />
      ),
    },
  ];

  return (
    <ImprList
      columns={columns}
      items={props.items}
      showHeader={false}
      sortLabel={props.sortLabel}
      customEmptyListNode={props.customEmptyListNode}
      pagination={false}
      rowKey={rowKey as string}
      handleSearch={props.handleSearch}
      headerExtraComponents={props.headerExtraComponents}
    />
  );
};
export default ImprListView;

export const RowMenuButton = ({ menuIcon, menuTitle, menuContent, menuClick }: MenuButtonProps) => (
  <>
    <Popover content={menuContent} title={menuTitle} arrow={false} trigger={'click'}>
      <Button type={'text'} onClick={menuClick} icon={menuIcon} />
    </Popover>
  </>
);
