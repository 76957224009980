import { useMutation } from '@tanstack/react-query';
import { directoriesSync } from '../../api/portalServices';
import { ErrorCode } from '../../errorHandler/constants';
import { useNotifications } from '../../errorHandler/context/Notifications';
import { AppError } from '../../errorHandler/errors';

function useDirectoriesSync() {
  const { emitError } = useNotifications();

  return useMutation({
    mutationFn: async (tenantId: string) => {
      await directoriesSync(tenantId);
    },
    onSuccess: async () => {
      console.debug('Directories sync call completed.');
    },
    onError: (e: Error) => {
      console.warn('Directories sync call failed!', e);
      emitError(new AppError({ code: ErrorCode.DIRECTORIES_SYNC_ERROR }));
    },
  });
}

export default useDirectoriesSync;
