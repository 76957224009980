// Copyright 2024, Imprivata, Inc.  All rights reserved.

import { Form } from 'antd';
import type React from 'react';
import type { Metadata } from '../../../api/types';
import './ExternalIdpInfo.less';
import { StorageKeys } from '../../../api/constants';
import * as api from '../../../api/setupServices';
import { ErrorCode } from '../../../errorHandler/constants';
import { useNotifications } from '../../../errorHandler/context/Notifications';
import { AppError } from '../../../errorHandler/errors';
import { SPANS_SETUP, tracer } from '../../../utils/tracer';
import IdpInfoForm, { type FormValues } from '../../../components/idpInfoForm/IdpInfoForm';

const ExternalIDPInfo = ({
  setIdpInfoSaved,
  setMetadataId,
}: {
  setIdpInfoSaved: (idpInfoSaved: boolean) => void;
  setMetadataId: (metadataId: string | null) => void;
}) => {
  const [form] = Form.useForm<FormValues>();
  const { emitError } = useNotifications();

  const isValidURL = (url: string): boolean => {
    try {
      new URL(url);
      return true;
    } catch (_) {
      return false;
    }
  };

  // Main form submit handler.
  //
  // NOTE: This will be used to display banners on validation errors
  // TODO: we must replace "Throw new Error" and "console.log" with proper UI error handling
  const finishForm = async () => {
    try {
      const values = form.getFieldsValue() as FormValues;
      if (!values.IdPName) {
        throw new Error('The Identity Provider Name (IdPName) is required.');
      }

      if (!values.IdPURL && !values.IdPXML) {
        throw new Error('Either the Identity Provider URL (IdPURL) or XML file (IdPXML) is required.');
      }

      if (values.IdPURL && !isValidURL(values.IdPURL)) {
        throw new Error('The provided URL is not valid.');
      }

      // Send data to backend
      // TODO: What is the response? Error handling?

      let metadata: Metadata = {
        rawMetadata: values.IdPXML,
      };

      if (values.IdPURL) {
        metadata = {
          metadataUrl: values.IdPURL,
        };
      }

      tracer.startSpan(SPANS_SETUP.icp_metadata_save);
      await api
        .icpMetadataSave({
          displayName: values.IdPName,
          metadata,
        })
        .then(async (response) => {
          setMetadataId(response.metadataId);
          sessionStorage.setItem(StorageKeys.EXT_IdP_USED, 'true');
          tracer.endSpan(SPANS_SETUP.icp_metadata_save);
          setIdpInfoSaved(true);
        })
        .catch((error) => {
          tracer.endSpan(SPANS_SETUP.icp_metadata_save, { error });
          console.log('Error:', error);
          emitError(new AppError(error));
        });
    } catch (error) {
      if (error instanceof Error) {
        console.log(`Form validation failed: ${error.message}`);
      } else {
        console.log('Unexpected error:', error);
      }
      emitError(new AppError({ code: ErrorCode.UNKNOWN }));
    }
  };

  return <IdpInfoForm form={form} onFinish={finishForm} />;
};

export default ExternalIDPInfo;
