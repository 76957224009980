import { useEffect } from 'react';
import { loadPendoScript } from '../utils/pendo';

const useInit = () => {
  useEffect(() => {
    loadPendoScript();
  }, []);
};

export default useInit;
