import { type Replacement, updateUrl } from '@imprivata-cloud/common';
import { CommonHeaders, type Endpoints } from './constants';

export interface RequestInitImpr extends RequestInit {
  workflowId?: string | null;
}

/// Shared fetch wrapper
export const fetcher = async (
  endpoint: (typeof Endpoints)[keyof typeof Endpoints] | string,
  { workflowId, ...options }: RequestInitImpr,
  tenantId?: string,
) => {
  const tenantIdParam = tenantId ? tenantId : new URLSearchParams(window.location.search).get('tenantId') || '';

  const apiUrl = getApiUrl(tenantIdParam);

  return fetch(`${apiUrl}${endpoint}`, {
    ...options,
    credentials: 'include',
    headers: {
      // todo: we need client name
      ...CommonHeaders,
      ...(options.headers || {}),
      ...(workflowId ? { 'Impr-Workflow-Id': workflowId } : {}),
    },
  })
    .then(async (response) => {
      if (response.ok) {
        return response
          .json()
          .then((json) => json)
          .catch(() => null); //response with empty body
      }
      const error = await response.json().then((json) => json);
      return Promise.reject(error.error);
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
      return Promise.reject(error);
    });
};

// Interpolate the API URL based on the current environment
export const getApiUrl = (tenantId?: string): string => {
  const host = tenantId ? tenantId : 'astra';
  const urls = _getUrl({ apiUrl: { sub: `${host}.sys` } });
  return urls?.apiUrl;
};

// Get the current environment and top-level domain from the current host
export function _getUrl(urlToBeGenerated: Record<string, Replacement>) {
  const url = window.location.host;
  const customRegex = /^(?:https?\:\/\/)?([A-Za-z0-9_]+)\w+/;
  const urlRegex = url?.includes('.app') ? undefined : customRegex;

  if (url?.includes('localhost')) {
    return {
      apiUrl: 'http://localhost:8080',
      metadataUrl: 'https://metadata.app.dev.imprivata.cloud',
    };
  }

  const updates = updateUrl(url, urlToBeGenerated, 'https://', urlRegex);

  return updates;
}

export function _getEnv(): string {
  const env = 'prod';
  const url = window.location.host;
  const match = url.match(/\b(int|dev|stg|localhost)\b/);
  if (match) {
    return match[1];
  }
  return env;
}
