import type React from 'react';
import type { ComponentType, ReactNode } from 'react';
import { useSession } from './context/Session';

export interface RolesProps {
  authorizedRoles: string[];
  defaultAction?: () => ReactNode | null;
}

export const withRole =
  (props: RolesProps) =>
  <T extends object>(WrappedComponent: ComponentType<T>) => {
    return (innerProps: T) => {
      const { userInfo } = useSession();
      console.debug('Authorized Roles', props);
      console.debug('Roles from session', userInfo?.roleType);

      const isAuthorized = userInfo?.roleType && props.authorizedRoles?.includes(userInfo.roleType);

      if (!isAuthorized) {
        if (props.defaultAction) {
          return props.defaultAction();
        }
        return <></>;
      }
      return <WrappedComponent {...innerProps} />;
    };
  };
