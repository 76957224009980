import { useMutation } from '@tanstack/react-query';
import { startEntraIdInitialSync } from '../../api/portalServices';
import { useNotifications } from '../../errorHandler/context/Notifications';
import { type ApiError, AppError } from '../../errorHandler/errors';

const useEntraIdInitialSync = () => {
  const { emitError } = useNotifications();

  return useMutation({
    mutationFn: async (tenantId: string) => {
      await startEntraIdInitialSync(tenantId);
    },
    onSuccess: async () => {
      console.log('Entra ID initial sync completed');
    },
    onError: (e: Error) => {
      console.log('Entra ID initial sync failed', e);
      const apiError = e as unknown as ApiError;
      emitError(new AppError(apiError));
    },
  });
};

export default useEntraIdInitialSync;
