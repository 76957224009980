// Copyright 2024, Imprivata, Inc.  All rights reserved.

import { InputBox } from '@imprivata-cloud/components';
import { Form } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ContinueButton from '../../../components/continueButton/ContinueButton';
import './OrganizationRoute.less';
import { useNotifications } from '../../../errorHandler/context/Notifications';
import { AppError } from '../../../errorHandler/errors';
import { setToLocalStorage } from '../../../utils/utils';
import { TRANSLATION_BUTTON, STEPS } from '../../constants';
import useOrganizationInfoSave, { getBase64 } from '../../hooks/useOrganizationInfoSave';
import { StorageKeys, SystemType } from '../../../api/constants';
import { useNavigateToNextStep } from '../../hooks/useNavigateToNextStep';
import LogoUpload from '../../../components/logoUpload/LogoUpload';
import type { RcFile } from 'antd/es/upload';
import * as api from '../../../api/setupServices';
import type { ErrorCode } from '../../../errorHandler/constants';

const ORGANIZATION_NAME_FIELD_NAME = 'organization name';
const EMAIL_FIELD_NAME = 'organization email';
const IMAGE_DETAILS_FIELD_NAME = 'image_details';

interface FormValues {
  [ORGANIZATION_NAME_FIELD_NAME]: string;
  [EMAIL_FIELD_NAME]: string;
  [IMAGE_DETAILS_FIELD_NAME]: RcFile;
}

const TRANSLATION_ROOT = 'setup.org-info.content';

const OrganizationRoute = () => {
  const [isConfirmation, setIsConfirmation] = useState<boolean>(false);
  const [form] = Form.useForm<FormValues>();
  const navigateToNextStep = useNavigateToNextStep();
  const organizationInfoSave = useOrganizationInfoSave(SystemType.SETUP);
  const { emitError } = useNotifications();
  const { t } = useTranslation();
  const { clearErrorNotifications } = useNotifications();

  const onConfirm = useCallback(async () => {
    try {
      console.log(form.getFieldValue(EMAIL_FIELD_NAME));
      setToLocalStorage(StorageKeys.ORG_EMAIL, form.getFieldValue(EMAIL_FIELD_NAME));

      await organizationInfoSave.mutateAsync({
        orgName: form.getFieldValue(ORGANIZATION_NAME_FIELD_NAME),
        logoFile: form.getFieldValue(IMAGE_DETAILS_FIELD_NAME),
      });
      navigateToNextStep(STEPS.SETUP_COMPLETE);
    } catch (error) {
      if (error instanceof AppError) {
        emitError(error);
      }
    }
  }, [form, organizationInfoSave, navigateToNextStep, emitError]);

  async function showConfirmation() {
    const isValid = await validateDomain();
    if (isValid) {
      setIsConfirmation(true);
    }
  }

  function showForm() {
    setIsConfirmation(false);
  }

  const closeBannerIfExists = () => {
    clearErrorNotifications();
  };

  async function validateDomain() {
    try {
      const domain = form.getFieldValue(EMAIL_FIELD_NAME)?.split('@')?.pop();
      const domainRequest = { names: [domain] };
      await api.validateDomain(domainRequest);
      return true;
    } catch (error) {
      const appError = error as AppError;
      const errorCode = `domain-${appError.code}`;
      emitError(new AppError({ code: errorCode as ErrorCode }));
      return false;
    }
  }

  // Rendering this way is important
  // If you remove and then show the form on back to form, you lose is dirty and Continue button gets disabled
  return (
    <>
      {isConfirmation && <ConfirmScreen data={form.getFieldsValue()} goBack={showForm} onConfirm={onConfirm} />}
      <div style={{ display: isConfirmation ? 'none' : 'block' }}>
        <Form form={form} layout="vertical" onFinish={showConfirmation}>
          <div className={'subtitle'} />

          {/* Organization Name */}
          <Form.Item name={ORGANIZATION_NAME_FIELD_NAME} rules={[{ required: true }]}>
            <InputBox label={t(`${TRANSLATION_ROOT}.name-label`)} required autoFocus />
          </Form.Item>

          <LogoUpload fieldName={IMAGE_DETAILS_FIELD_NAME} fieldValue={form.getFieldValue(IMAGE_DETAILS_FIELD_NAME)} />

          {/* Organization Email */}
          <Form.Item
            name={EMAIL_FIELD_NAME}
            extra={t(`${TRANSLATION_ROOT}.email-sub-label`)}
            rules={[{ required: true, type: 'email' }]}
          >
            <InputBox label={t(`${TRANSLATION_ROOT}.email-label`)} required />
          </Form.Item>

          {/* Continue */}
          <ContinueButton label={t(TRANSLATION_BUTTON)} htmlType="submit" onClick={closeBannerIfExists} validateDirty />
        </Form>
      </div>
    </>
  );
};

const ConfirmScreen = ({
  data,
  goBack,
  onConfirm,
}: { data: FormValues; goBack: () => void; onConfirm: () => void }) => {
  const { t } = useTranslation();
  const TRANSLATION_ROOT = 'setup.org-info-confirm';
  const [imageUrl, setImageUrl] = useState<string | undefined>();

  useEffect(() => {
    // no error handling since if this fails, it fails in the LogoUpload
    getBase64(data[IMAGE_DETAILS_FIELD_NAME]).then((url) => setImageUrl(url));
  }, [data[IMAGE_DETAILS_FIELD_NAME]]);

  console.log('[OrganizationVerify] data:', data);
  return (
    <div>
      <p>{t(`${TRANSLATION_ROOT}.content.label`)}</p>
      <p>{t(`${TRANSLATION_ROOT}.content.name-logo-label`)}</p>
      <h2>{data?.[ORGANIZATION_NAME_FIELD_NAME]}</h2>
      <img src={imageUrl} alt="org logo" />
      <p>{t(`${TRANSLATION_ROOT}.content.domain-name-label`)}</p>
      <h3>{data?.[EMAIL_FIELD_NAME]?.split('@')?.pop()}</h3>
      <Form>
        <ContinueButton
          className="focus-outline"
          autoFocus
          secondaryButton={{ label: t('common.back-button'), onClick: goBack }}
          onClick={onConfirm}
        >
          {t('common.continue-button')}
        </ContinueButton>
      </Form>
    </div>
  );
};

export default OrganizationRoute;
