// Copyright 2025, Imprivata, Inc.  All rights reserved.

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ImprChip, SearchTagSelect } from '@imprivata-cloud/components';
import { useGroups } from '../../hooks/useGroups';
import './GroupsModalContent.less';

const TRANSLATION_ROOT = 'portal.settings.groups';

interface GroupsModalContentProps {
  groupsIdToMark: string[];
  setGroupsIdToMark: (groups: string[]) => void;
  groupsToMark: string[];
  setGroupsToMark: (groups: string[]) => void;
  groupsToUnmark: string[];
  setGroupsToUnmark: (groups: string[]) => void;
}

const GroupsModalContent = ({
  groupsIdToMark,
  setGroupsIdToMark,
  groupsToMark,
  setGroupsToMark,
  groupsToUnmark,
  setGroupsToUnmark,
}: GroupsModalContentProps) => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState('');
  const { addedGroups, availableGroups, refetchAvailableGroups } = useGroups(searchValue);

  const handleSearch = (value: string) => {
    setSearchValue(value);
    if (value.length >= 2) {
      refetchAvailableGroups();
    }
  };

  const handleGroupChange = (newSelectedGroups: string[]) => {
    setGroupsIdToMark(
      availableGroups.filter((group) => newSelectedGroups.includes(group.groupName)).map((group) => group.groupId),
    );
    setGroupsToMark(newSelectedGroups);
    setSearchValue('');
  };

  const handleRemoveGroup = (group: string) => {
    setGroupsToUnmark([...groupsToUnmark, group]);
  };
  return (
    <div className="groups-modal-content">
      {/* Subtitle and link instructions */}
      <p>
        {t(`${TRANSLATION_ROOT}.subtitle`)} &emsp;
        <a href="https://docs.imprivata.com/">{t(`${TRANSLATION_ROOT}.doc-link`)}</a>
      </p>

      {/* Groups already added */}
      <div className="groups-container">
        {addedGroups
          .filter((group) => !groupsToUnmark.includes(group.groupId))
          .map((group) => (
            <ImprChip
              key={group.groupId}
              label={group.groupName}
              color="processing"
              closable
              onClose={() => handleRemoveGroup(group.groupId)}
            />
          ))}
      </div>

      {/* Enter new groups */}
      <p className="enter-groups-title">{t(`${TRANSLATION_ROOT}.enter-groups`)}</p>
      <SearchTagSelect
        options={availableGroups.map((group) => group.groupName)}
        value={groupsToMark}
        searchValue={searchValue}
        onSearch={handleSearch}
        onChange={handleGroupChange}
      />
    </div>
  );
};

export default GroupsModalContent;
