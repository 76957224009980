import type { UserInfo } from '../api/types';
import { getFeatureFlags } from '../featureFlagsUtils';

export const loadPendoScript = () => {
  const { PENDO_API_KEY } = getFeatureFlags();

  if (!window.pendo && PENDO_API_KEY) {
    const script = document.createElement('script');
    script.src = `https://cdn.pendo.io/agent/static/${PENDO_API_KEY}/pendo.js`;
    script.async = true;
    document.body.appendChild(script);
  }
};

export const initializePendo = (tenantId?: string, userInfo?: UserInfo) => {
  if (window.pendo && userInfo) {
    window.pendo.initialize({
      visitor: {
        id: userInfo.productExperienceVisitorId,
        role: userInfo.roleType,
      },
      account: {
        id: tenantId,
      },
    });
  }
};
